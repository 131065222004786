
class SentinelMinimeteos {
	constructor(
		pressure,
		relativeHumidity,
		temperature,
		utc
	) {
		this.pressure = pressure;
		this.relativeHumidity = relativeHumidity;
		this.temperature = temperature;
		this.utc = utc;
	}
}

export default SentinelMinimeteos;
