import Sentinel from "./Sentinel";
import SentinelAuxs from "./SentinelAuxs";
import SentinelBands from "./SentinelBands";
import SentinelFonos from "./SentinelFonos";
import SentinelMaxFonos from "./SentinelMaxFonos";
import SentinelMaxAuxs from "./SentinelMaxAuxs";
import SentinelMaxBands from "./SentinelMaxBands";
import SentinelMaxPmts from "./SentinelMaxPmts";
import SentinelPMI from "./SentinelPMI";
import SentinelSismas from "./SentinelSismas";
import SentinelMaxSismas from "./SentinelMaxSismas";
import SentinelMinimeteos from "./SentinelMinimeteos";


import _ from "lodash";
import SentinelMeteos from "./SentinelMeteos";

const baseUrl = "https://www.ecosentinel.it/api/v1";

/*
const mockSentinels = [
  {
    id: 1,
    matricola: "eco_s1",
    latitude: 43.19412,
    longitude: 12.12780,
    cap: "06065",
    extra: "Sentinella Umbria Verde",
    sensor_values: {
      band: 1,
      pmt_10: 2,
      pmt_2_5: 2,
      aux: 1 ,
    }
  },
  {
    id: 2,
    matricola: "eco_s2",
    latitude: 43.72142,
    longitude: 12.15390,
    cap: "06070",
    extra: "Sentinella Umbria Verde",
    sensor_values: {
      band: 1,
      pmt_10: 2,
      pmt_2_5: 3,
      aux: 1,
    }
  }
];
*/

//function for retrieving sentinel giving its matricola
// GET /get_sentinel/<matricola>
async function getSentinel(matricola) {
	const url = `/get_sentinel/${matricola}`;
	let token = localStorage.getItem("access_token");
	const response = await fetch(`${baseUrl}${url}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token ? "Bearer " + token.replace(/"/g, "") : "None",
		},
	});
	const sentinelJson = await response.json();
	const sentinel = sentinelJson.data;

	if (response.ok) {
		const res = new Sentinel(
			sentinel.id,
			sentinel.matricola,
			sentinel.longitude,
			sentinel.latitude,
			sentinel.cap,
			sentinel.sensor_values,
			sentinel.extra
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelJson };
		throw err;
	}
}

//function for retrieving sentinels
// GET  /get_all
async function getAllSentinels() {
	const url = "/get_all";
	let token = localStorage.getItem("access_token");
	const response = await fetch(`${baseUrl}${url}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token ? "Bearer " + token.replace(/"/g, "") : "None",
		},
	});

	const sentinelsJson = await response.json();
	if (response.error && response.error === "token_expired") {
		const err = { status: response.error, errObj: sentinelsJson };
		throw err;
	}
	if (response.ok) {
		const sentinels = sentinelsJson.data;

		const res = sentinels.map(
			(sentinel, index) =>
				new Sentinel(
					sentinel.id,
					sentinel.matricola,
					sentinel.longitude,
					sentinel.latitude,
					sentinel.cap,
					sentinel.sensor_values,
					sentinel.extra
				)
		);
		if (token === null) {
			const fakeSentinels = fakeSentinelsArray.map((parsedJson) => ({
				...res[0],
				matricola: parsedJson.city,
				longitude: parsedJson.lng,
				latitude: parsedJson.lat,
				private: true,
			}));
			return _.concat(res, fakeSentinels);
		}
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelsJson };
		throw err;
	}
}

//function for retrieving sentinel's data
// GET /get_bands/<matricola>/<numeroGiorni>
async function getSentinelBands(sentinel, days) {
	const url = `/get_bands/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) => new SentinelBands(data.emin, data.emax, data.eavg, data.utc)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /get_pmts/<matricola>/<numeroGiorni>
async function getSentinelPMI(sentinel, days) {
	const url = `/get_pmts/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) => new SentinelPMI(data.pmt_2_5, data.pmt_10, data.utc)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /get_auxs/<matricola>/<numeroGiorni>
async function getSentinelAuxs(sentinel, days) {
	const url = `/get_auxs/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelAuxs(
					data.band_max,
					data.band_1,
					data.band_2,
					data.band_3,
					data.band_4,
					data.band_5,
					data.band_6,
					data.band_7,
					data.band_8,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /get_fonos/<matricola>/<numeroGiorni>
async function getSentinelFonos(sentinel, days) {
	const url = `/get_fonos/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelFonos(
					// data.eq_min,
					// data.eq_max,
					//data.eq_avg,
					//data.ist_min,
					data.ist_max,
					data.ist_avg,
					// data.pond_min,
					// data.pond_max,
					// data.pond_avg,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}


// GET /get_sismas/<matricola>/<numeroGiorni>
async function getSentinelSismas(sentinel, days) {
	const url = `/get_sismas/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelSismas(
					// data.eq_min,
					// data.eq_max,
					//data.eq_avg,
					//data.ist_min,
					data.max,
					data.avg,
					// data.pond_min,
					// data.pond_max,
					// data.pond_avg,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}


// GET /get_meteos/<matricola>/<numeroGiorni>
async function getSentinelMeteos(sentinel, days) {
	const url = `/get_meteos/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelMeteos(
					data.wind_direction,
					data.wind_speed,
					data.wind_fixed_direction,
					data.pressure,
					data.relative_humidity,
					data.temperature,
					data.dewpoint,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /last_meteos/<matricola>
async function getSentinelLastMeteos(sentinel) {
	const url = `/last_meteos/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelMeteos(
					data.wind_direction,
					data.wind_speed,
					data.wind_fixed_direction,
					data.pressure,
					data.relative_humidity,
					data.temperature,
					data.dewpoint,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}


// GET /get_minimeteos/<matricola>/<numeroGiorni>
async function getSentinelMinimeteos(sentinel, days) {
	const url = `/get_minimeteos/${sentinel.matricola}/${days}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelMinimeteos(
					data.pressure,
					data.relative_humidity,
					data.temperature,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /last_minimeteos/<matricola>
async function getSentinelLastMinimeteos(sentinel) {
	const url = `/last_minimeteos/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data.map(
			(data) =>
				new SentinelMeteos(
					data.pressure,
					data.relative_humidity,
					data.temperature,
					data.utc
				)
		);
		return sortByUtc(res);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /last_auxs/<matricola>
async function getSentinelLastAuxs(sentinel) {
	const url = `/last_auxs/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelDataJson = await response.json();
	if (response.ok) {
		const res = sentinelDataJson.data;
		return new SentinelAuxs(
			res.band_max,
			res.band_1,
			res.band_2,
			res.band_3,
			res.band_4,
			res.band_5,
			res.band_6,
			res.band_7,
			res.band_8,
			res.utc
		);
	} else {
		const err = { status: response.status, errObj: sentinelDataJson };
		throw err;
	}
}

// GET /max_bands/<matricola>
async function getSentinelMaxBands(sentinel) {
	const url = `/max_bands/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelMaxBandsJson = await response.json();
	if (response.ok) {
		const res = new SentinelMaxBands(
			sentinelMaxBandsJson.data.max_week_emax[0],
			sentinelMaxBandsJson.data.max_month_emax[0],
			sentinelMaxBandsJson.data.max_year_emax[0],
			sentinelMaxBandsJson.data.max_week_eavg[0],
			sentinelMaxBandsJson.data.max_month_eavg[0],
			sentinelMaxBandsJson.data.max_year_eavg[0],
			sentinelMaxBandsJson.data.max_week_emin[0],
			sentinelMaxBandsJson.data.max_month_emin[0],
			sentinelMaxBandsJson.data.max_year_emin[0]
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelMaxBandsJson };
		throw err;
	}
}
// GET /max_pmts/<matricola>
async function getSentinelMaxPmts(sentinel) {
	const url = `/max_pmts/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelMaxPmtsJson = await response.json();
	if (response.ok) {
		const res = new SentinelMaxPmts(
			sentinelMaxPmtsJson.data.max_week_pmt_2_5[0],
			sentinelMaxPmtsJson.data.max_month_pmt_2_5[0],
			sentinelMaxPmtsJson.data.max_year_pmt_2_5[0],
			sentinelMaxPmtsJson.data.max_week_pmt_10[0],
			sentinelMaxPmtsJson.data.max_month_pmt_10[0],
			sentinelMaxPmtsJson.data.max_year_pmt_10[0]
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelMaxPmtsJson };
		throw err;
	}
}
// GET /max_auxs/<matricola>
async function getSentinelMaxAuxs(sentinel) {
	const url = `/max_auxs/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelMaxAuxsJson = await response.json();
	if (response.ok) {
		const res = new SentinelMaxAuxs(
			sentinelMaxAuxsJson.data.max_week[0],
			sentinelMaxAuxsJson.data.max_month[0],
			sentinelMaxAuxsJson.data.max_year[0]
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelMaxAuxsJson };
		throw err;
	}
}

// GET /max_fonos/<matricola>
async function getSentinelMaxFonos(sentinel) {
	const url = `/max_fonos/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelMaxFonosJson = await response.json();
	if (response.ok) {
		const res = new SentinelMaxFonos(
			sentinelMaxFonosJson.data.max_week_ist,
			sentinelMaxFonosJson.data.max_month_ist,
			sentinelMaxFonosJson.data.max_year_ist
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelMaxFonosJson };
		throw err;
	}
}

// GET /max_sismas/<matricola>
async function getSentinelMaxSismas(sentinel) {
	const url = `/max_sismas/${sentinel.matricola}`;
	const response = await fetch(`${baseUrl}${url}`);
	const sentinelMaxSismasJson = await response.json();
	if (response.ok) {
		const res = new SentinelMaxSismas(
			sentinelMaxSismasJson.data.max_week,
			sentinelMaxSismasJson.data.max_month,
			sentinelMaxSismasJson.data.max_year
		);
		return res;
	} else {
		const err = { status: response.status, errObj: sentinelMaxSismasJson };
		throw err;
	}
}



function sortByUtc(values) {
	return values.sort((a, b) => (a.utc > b.utc ? 1 : -1));
}

const API = {
	getSentinel,
	getAllSentinels,
	getSentinelBands,
	getSentinelPMI,
	getSentinelMaxBands,
	getSentinelMaxPmts,
	getSentinelMaxAuxs,
	getSentinelLastAuxs,
	getSentinelAuxs,
	getSentinelFonos,
	getSentinelSismas,
	getSentinelMaxSismas,
	getSentinelMeteos,
	getSentinelMinimeteos,
	getSentinelMaxFonos,
	getSentinelLastMeteos,
	getSentinelLastMinimeteos,
};

export default API;

const fakeSentinelsArray = [
	{
		city: "Rome",
		lat: "41.8931",
		lng: "12.4828",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lazio",
		capital: "primary",
		population: "2872800",
		population_proper: "2872800",
	},
	{
		city: "Milan",
		lat: "45.4669",
		lng: "9.1900",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "admin",
		population: "1366180",
		population_proper: "1366180",
	},
	{
		city: "Naples",
		lat: "40.8333",
		lng: "14.2500",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "admin",
		population: "966144",
		population_proper: "966144",
	},
	{
		city: "Turin",
		lat: "45.0667",
		lng: "7.7000",
		country: "Italy",
		iso2: "IT",
		admin_name: "Piedmont",
		capital: "admin",
		population: "870952",
		population_proper: "870952",
	},
	{
		city: "Palermo",
		lat: "38.1157",
		lng: "13.3613",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "admin",
		population: "668405",
		population_proper: "668405",
	},
	{
		city: "Genoa",
		lat: "44.4072",
		lng: "8.9340",
		country: "Italy",
		iso2: "IT",
		admin_name: "Liguria",
		capital: "admin",
		population: "580097",
		population_proper: "580097",
	},
	{
		city: "Bologna",
		lat: "44.4939",
		lng: "11.3428",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "admin",
		population: "389261",
		population_proper: "389261",
	},
	{
		city: "Florence",
		lat: "43.7714",
		lng: "11.2542",
		country: "Italy",
		iso2: "IT",
		admin_name: "Tuscany",
		capital: "admin",
		population: "380948",
		population_proper: "380948",
	},
	{
		city: "Bari",
		lat: "41.1253",
		lng: "16.8667",
		country: "Italy",
		iso2: "IT",
		admin_name: "Puglia",
		capital: "admin",
		population: "323370",
		population_proper: "323370",
	},
	{
		city: "Catania",
		lat: "37.5027",
		lng: "15.0873",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "minor",
		population: "311584",
		population_proper: "311584",
	},
	{
		city: "Venice",
		lat: "45.4397",
		lng: "12.3319",
		country: "Italy",
		iso2: "IT",
		admin_name: "Veneto",
		capital: "admin",
		population: "261321",
		population_proper: "261321",
	},
	{
		city: "Verona",
		lat: "45.4386",
		lng: "10.9928",
		country: "Italy",
		iso2: "IT",
		admin_name: "Veneto",
		capital: "minor",
		population: "257275",
		population_proper: "257275",
	},
	{
		city: "Messina",
		lat: "38.1936",
		lng: "15.5542",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "minor",
		population: "234293",
		population_proper: "234293",
	},
	{
		city: "Padova",
		lat: "45.4064",
		lng: "11.8778",
		country: "Italy",
		iso2: "IT",
		admin_name: "Veneto",
		capital: "minor",
		population: "210440",
		population_proper: "210440",
	},
	{
		city: "Trieste",
		lat: "45.6503",
		lng: "13.7703",
		country: "Italy",
		iso2: "IT",
		admin_name: "Friuli-Venezia Giulia",
		capital: "admin",
		population: "204338",
		population_proper: "204338",
	},
	{
		city: "Taranto",
		lat: "40.4181",
		lng: "17.2408",
		country: "Italy",
		iso2: "IT",
		admin_name: "Puglia",
		capital: "minor",
		population: "198283",
		population_proper: "198283",
	},
	{
		city: "Brescia",
		lat: "45.5389",
		lng: "10.2203",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "minor",
		population: "196745",
		population_proper: "196745",
	},
	{
		city: "Parma",
		lat: "44.8015",
		lng: "10.3280",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "minor",
		population: "195687",
		population_proper: "195687",
	},
	{
		city: "Prato",
		lat: "43.8808",
		lng: "11.0966",
		country: "Italy",
		iso2: "IT",
		admin_name: "Tuscany",
		capital: "minor",
		population: "193325",
		population_proper: "193325",
	},
	{
		city: "Modena",
		lat: "44.6458",
		lng: "10.9257",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "minor",
		population: "185273",
		population_proper: "185273",
	},
	{
		city: "Reggio di Calabria",
		lat: "38.1144",
		lng: "15.6500",
		country: "Italy",
		iso2: "IT",
		admin_name: "Calabria",
		capital: "minor",
		population: "181447",
		population_proper: "181447",
	},
	{
		city: "Reggio Emilia",
		lat: "44.7000",
		lng: "10.6333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "minor",
		population: "171944",
		population_proper: "171944",
	},
	{
		city: "Perugia",
		lat: "43.1121",
		lng: "12.3888",
		country: "Italy",
		iso2: "IT",
		admin_name: "Umbria",
		capital: "admin",
		population: "165683",
		population_proper: "165683",
	},
	{
		city: "Ravenna",
		lat: "44.4178",
		lng: "12.1994",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "minor",
		population: "159115",
		population_proper: "159115",
	},
	{
		city: "Livorno",
		lat: "43.5500",
		lng: "10.3167",
		country: "Italy",
		iso2: "IT",
		admin_name: "Tuscany",
		capital: "minor",
		population: "158371",
		population_proper: "158371",
	},
	{
		city: "Cagliari",
		lat: "39.2167",
		lng: "9.1167",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sardegna",
		capital: "admin",
		population: "154106",
		population_proper: "154106",
	},
	{
		city: "Foggia",
		lat: "41.4584",
		lng: "15.5519",
		country: "Italy",
		iso2: "IT",
		admin_name: "Puglia",
		capital: "minor",
		population: "151372",
		population_proper: "151372",
	},
	{
		city: "Rimini",
		lat: "44.0594",
		lng: "12.5683",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "minor",
		population: "149403",
		population_proper: "149403",
	},
	{
		city: "Salerno",
		lat: "40.6806",
		lng: "14.7597",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "minor",
		population: "133970",
		population_proper: "133970",
	},
	// {
	//   city: "Ferrara",
	//   lat: "44.8353",
	//   lng: "11.6199",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Emilia-Romagna",
	//   capital: "minor",
	//   population: "132278",
	//   population_proper: "132278",
	// },
	// {
	//   city: "Latina",
	//   lat: "41.4676",
	//   lng: "12.9037",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Lazio",
	//   capital: "minor",
	//   population: "126470",
	//   population_proper: "126470",
	// },
	// {
	//   city: "Giugliano in Campania",
	//   lat: "40.9319",
	//   lng: "14.1956",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Campania",
	//   capital: "",
	//   population: "124361",
	//   population_proper: "124361",
	// },
	// {
	//   city: "Monza",
	//   lat: "45.5836",
	//   lng: "9.2736",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Lombardy",
	//   capital: "minor",
	//   population: "123598",
	//   population_proper: "123598",
	// },
	// {
	//   city: "Siracusa",
	//   lat: "37.0692",
	//   lng: "15.2875",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Sicilia",
	//   capital: "minor",
	//   population: "121171",
	//   population_proper: "121171",
	// },
	// {
	//   city: "Bergamo",
	//   lat: "45.6950",
	//   lng: "9.6700",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Lombardy",
	//   capital: "minor",
	//   population: "120923",
	//   population_proper: "120923",
	// },
	// {
	//   city: "Pescara",
	//   lat: "42.4643",
	//   lng: "14.2142",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Abruzzo",
	//   capital: "minor",
	//   population: "119217",
	//   population_proper: "119217",
	// },
	// {
	//   city: "Trento",
	//   lat: "46.0667",
	//   lng: "11.1167",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Trentino-Alto Adige",
	//   capital: "admin",
	//   population: "117997",
	//   population_proper: "117997",
	// },
	// {
	//   city: "Forlì",
	//   lat: "44.2225",
	//   lng: "12.0408",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Emilia-Romagna",
	//   capital: "minor",
	//   population: "117863",
	//   population_proper: "117863",
	// },
	// {
	//   city: "Sassari",
	//   lat: "40.7267",
	//   lng: "8.5592",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Sardegna",
	//   capital: "minor",
	//   population: "116641",
	//   population_proper: "116641",
	// },
	// {
	//   city: "Vicenza",
	//   lat: "45.5500",
	//   lng: "11.5500",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Veneto",
	//   capital: "minor",
	//   population: "111620",
	//   population_proper: "111620",
	// },
	// {
	//   city: "Terni",
	//   lat: "42.5667",
	//   lng: "12.6500",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Umbria",
	//   capital: "minor",
	//   population: "111189",
	//   population_proper: "111189",
	// },
	// {
	//   city: "Bolzano",
	//   lat: "46.5000",
	//   lng: "11.3500",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Trentino-Alto Adige",
	//   capital: "minor",
	//   population: "107317",
	//   population_proper: "107317",
	// },
	// {
	//   city: "Novara",
	//   lat: "45.4500",
	//   lng: "8.6167",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Piedmont",
	//   capital: "minor",
	//   population: "104183",
	//   population_proper: "104183",
	// },
	// {
	//   city: "Piacenza",
	//   lat: "45.0500",
	//   lng: "9.7000",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Emilia-Romagna",
	//   capital: "minor",
	//   population: "103082",
	//   population_proper: "103082",
	// },
	// {
	//   city: "Ancona",
	//   lat: "43.6167",
	//   lng: "13.5167",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Marche",
	//   capital: "admin",
	//   population: "100924",
	//   population_proper: "100924",
	// },
	// {
	//   city: "Andria",
	//   lat: "41.2317",
	//   lng: "16.3083",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Puglia",
	//   capital: "minor",
	//   population: "99857",
	//   population_proper: "99857",
	// },
	// {
	//   city: "Udine",
	//   lat: "46.0667",
	//   lng: "13.2333",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Friuli-Venezia Giulia",
	//   capital: "minor",
	//   population: "99518",
	//   population_proper: "99518",
	// },
	// {
	//   city: "Arezzo",
	//   lat: "43.4631",
	//   lng: "11.8781",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Tuscany",
	//   capital: "minor",
	//   population: "99419",
	//   population_proper: "99419",
	// },
	// {
	//   city: "Cesena",
	//   lat: "44.1333",
	//   lng: "12.2333",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Emilia-Romagna",
	//   capital: "",
	//   population: "96760",
	//   population_proper: "96760",
	// },
	// {
	//   city: "Lecce",
	//   lat: "40.3520",
	//   lng: "18.1691",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Puglia",
	//   capital: "minor",
	//   population: "95441",
	//   population_proper: "95441",
	// },
	// {
	//   city: "Pesaro",
	//   lat: "43.9102",
	//   lng: "12.9133",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Marche",
	//   capital: "minor",
	//   population: "94958",
	//   population_proper: "94958",
	// },
	// {
	//   city: "Barletta",
	//   lat: "41.3167",
	//   lng: "16.2833",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Puglia",
	//   capital: "minor",
	//   population: "94477",
	//   population_proper: "94477",
	// },
	// {
	//   city: "Alessandria",
	//   lat: "44.9133",
	//   lng: "8.6200",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Piedmont",
	//   capital: "minor",
	//   population: "93980",
	//   population_proper: "93980",
	// },
	// {
	//   city: "La Spezia",
	//   lat: "44.1080",
	//   lng: "9.8289",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Liguria",
	//   capital: "minor",
	//   population: "93311",
	//   population_proper: "93311",
	// },
	// {
	//   city: "Pistoia",
	//   lat: "43.9333",
	//   lng: "10.9167",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Tuscany",
	//   capital: "minor",
	//   population: "90195",
	//   population_proper: "90195",
	// },
	// {
	//   city: "Pisa",
	//   lat: "43.7167",
	//   lng: "10.4000",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Tuscany",
	//   capital: "minor",
	//   population: "90118",
	//   population_proper: "90118",
	// },
	// {
	//   city: "Catanzaro",
	//   lat: "38.9100",
	//   lng: "16.5875",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Calabria",
	//   capital: "admin",
	//   population: "89718",
	//   population_proper: "89718",
	// },
	// {
	//   city: "Mestre",
	//   lat: "45.4906",
	//   lng: "12.2381",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Veneto",
	//   capital: "",
	//   population: "89376",
	//   population_proper: "89376",
	// },
	// {
	//   city: "Lucca",
	//   lat: "43.8500",
	//   lng: "10.5167",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Tuscany",
	//   capital: "minor",
	//   population: "89243",
	//   population_proper: "89243",
	// },
	// {
	//   city: "Brindisi",
	//   lat: "40.6383",
	//   lng: "17.9458",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Puglia",
	//   capital: "minor",
	//   population: "87141",
	//   population_proper: "87141",
	// },
	// {
	//   city: "Torre del Greco",
	//   lat: "40.7853",
	//   lng: "14.3953",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Campania",
	//   capital: "",
	//   population: "85332",
	//   population_proper: "85332",
	// },
	// {
	//   city: "Treviso",
	//   lat: "45.6722",
	//   lng: "12.2422",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Veneto",
	//   capital: "minor",
	//   population: "84954",
	//   population_proper: "84954",
	// },
	// {
	//   city: "Busto Arsizio",
	//   lat: "45.6120",
	//   lng: "8.8518",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Lombardy",
	//   capital: "",
	//   population: "83405",
	//   population_proper: "83405",
	// },
	// {
	//   city: "Como",
	//   lat: "45.8103",
	//   lng: "9.0861",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Lombardy",
	//   capital: "minor",
	//   population: "83320",
	//   population_proper: "83320",
	// },
	// {
	//   city: "Marsala",
	//   lat: "37.7981",
	//   lng: "12.4342",
	//   country: "Italy",
	//   iso2: "IT",
	//   admin_name: "Sicilia",
	//   capital: "",
	//   population: "82802",
	//   population_proper: "82802",
	// },
	{
		city: "Grosseto",
		lat: "42.7722",
		lng: "11.1089",
		country: "Italy",
		iso2: "IT",
		admin_name: "Tuscany",
		capital: "minor",
		population: "82036",
		population_proper: "82036",
	},
	{
		city: "Sesto San Giovanni",
		lat: "45.5333",
		lng: "9.2333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "",
		population: "81773",
		population_proper: "81773",
	},
	{
		city: "Pozzuoli",
		lat: "40.8231",
		lng: "14.1222",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "",
		population: "81141",
		population_proper: "81141",
	},
	{
		city: "Varese",
		lat: "45.8167",
		lng: "8.8333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "minor",
		population: "80544",
		population_proper: "80544",
	},
	{
		city: "Fiumicino",
		lat: "41.7667",
		lng: "12.2333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lazio",
		capital: "",
		population: "79630",
		population_proper: "79630",
	},
	{
		city: "Casoria",
		lat: "40.9000",
		lng: "14.3000",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "",
		population: "77087",
		population_proper: "77087",
	},
	{
		city: "Asti",
		lat: "44.9000",
		lng: "8.2069",
		country: "Italy",
		iso2: "IT",
		admin_name: "Piedmont",
		capital: "minor",
		population: "76211",
		population_proper: "76211",
	},
	{
		city: "Cinisello Balsamo",
		lat: "45.5500",
		lng: "9.2167",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "",
		population: "75723",
		population_proper: "75723",
	},
	{
		city: "Caserta",
		lat: "41.0667",
		lng: "14.3333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "minor",
		population: "75561",
		population_proper: "75561",
	},
	{
		city: "Gela",
		lat: "37.0667",
		lng: "14.2500",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "",
		population: "74858",
		population_proper: "74858",
	},
	{
		city: "Ragusa",
		lat: "36.9250",
		lng: "14.7306",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "minor",
		population: "73638",
		population_proper: "73638",
	},
	{
		city: "Pavia",
		lat: "45.1853",
		lng: "9.1550",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "minor",
		population: "72773",
		population_proper: "72773",
	},
	{
		city: "Cremona",
		lat: "45.1333",
		lng: "10.0333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lombardy",
		capital: "minor",
		population: "72077",
		population_proper: "72077",
	},
	{
		city: "Carpi",
		lat: "44.7833",
		lng: "10.8850",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "",
		population: "71148",
		population_proper: "71148",
	},
	{
		city: "Quartu Sant’Elena",
		lat: "39.2291",
		lng: "9.2649",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sardegna",
		capital: "",
		population: "70879",
		population_proper: "70879",
	},
	{
		city: "Altamura",
		lat: "40.8167",
		lng: "16.5500",
		country: "Italy",
		iso2: "IT",
		admin_name: "Puglia",
		capital: "",
		population: "70514",
		population_proper: "70514",
	},
	{
		city: "Imola",
		lat: "44.3531",
		lng: "11.7147",
		country: "Italy",
		iso2: "IT",
		admin_name: "Emilia-Romagna",
		capital: "",
		population: "69936",
		population_proper: "69936",
	},
	{
		city: "L’Aquila",
		lat: "42.3540",
		lng: "13.3920",
		country: "Italy",
		iso2: "IT",
		admin_name: "Abruzzo",
		capital: "admin",
		population: "69439",
		population_proper: "69439",
	},
	{
		city: "Massa",
		lat: "44.0333",
		lng: "10.1333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Tuscany",
		capital: "minor",
		population: "69037",
		population_proper: "69037",
	},
	{
		city: "Trapani",
		lat: "38.0175",
		lng: "12.5150",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "minor",
		population: "67923",
		population_proper: "67923",
	},
	{
		city: "Viterbo",
		lat: "42.4186",
		lng: "12.1042",
		country: "Italy",
		iso2: "IT",
		admin_name: "Lazio",
		capital: "minor",
		population: "67798",
		population_proper: "67798",
	},
	{
		city: "Cosenza",
		lat: "39.3000",
		lng: "16.2500",
		country: "Italy",
		iso2: "IT",
		admin_name: "Calabria",
		capital: "minor",
		population: "67239",
		population_proper: "67239",
	},
	{
		city: "Potenza",
		lat: "40.6333",
		lng: "15.8000",
		country: "Italy",
		iso2: "IT",
		admin_name: "Basilicata",
		capital: "admin",
		population: "67211",
		population_proper: "67211",
	},
	{
		city: "Castellammare di Stabia",
		lat: "40.7000",
		lng: "14.4833",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "",
		population: "65922",
		population_proper: "65922",
	},
	{
		city: "Afragola",
		lat: "40.9167",
		lng: "14.3167",
		country: "Italy",
		iso2: "IT",
		admin_name: "Campania",
		capital: "",
		population: "64817",
		population_proper: "64817",
	},
	{
		city: "Vittoria",
		lat: "36.9500",
		lng: "14.5333",
		country: "Italy",
		iso2: "IT",
		admin_name: "Sicilia",
		capital: "",
		population: "64212",
		population_proper: "64212",
	},
];
