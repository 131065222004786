import SentinelMaxBands from "../api/SentinelMaxBands";
import SentinelMaxPmts from "../api/SentinelMaxPmts";
import SentinelPMI from "../api/SentinelPMI";
import SentinelBands from "../api/SentinelBands";
import SentinelAuxs from "../api/SentinelAuxs";
import SentinelMaxAuxs from "../api/SentinelMaxAuxs";
import SentinelMaxFonos from "./SentinelMaxFonos";
import SentinelFonos from "./SentinelFonos";
import SentinelMeteos from "./SentinelMeteos";
import SentinelMinimeteos from "./SentinelMinimeteos";
import SentinelSismas from "./SentinelSismas";
import SentinelMaxSismas from "./SentinelMaxSismas";


class ChartData {
	constructor(
		bands,
		pmi,
		lastAuxs,
		auxs,
		fonos,
		meteos,
		minimeteos,
		sismas,
		maxBands,
		maxPmts,
		maxAuxs,
		maxFonos,
		maxSismas,
		lastMeteos,
		lastMinimeteos
	) {
		this.bands = bands || [new SentinelBands()];
		this.pmi = pmi || [new SentinelPMI()];
		this.lastAuxs = lastAuxs || new SentinelAuxs();
		this.auxs = auxs || [new SentinelAuxs()];
		this.fonos = fonos || [new SentinelFonos()];
		this.maxBands = maxBands || new SentinelMaxBands();
		this.maxPmts = maxPmts || new SentinelMaxPmts();
		this.maxAuxs = maxAuxs || new SentinelMaxAuxs();
		this.maxFonos = maxFonos || new SentinelMaxFonos();
		this.meteos = meteos || new SentinelMeteos();
		this.minimeteos = minimeteos || new SentinelMinimeteos();
		this.sismas = sismas || [new SentinelSismas()];
		this.maxSismas = maxSismas || new SentinelMaxSismas();
		this.lastMeteos = lastMeteos || new SentinelMeteos();
		this.lastMinimeteos = lastMinimeteos || new SentinelMinimeteos();
	}
}
export default ChartData;
